import _compact from 'lodash/compact';

import resolve from '../../modules/api/resolve';
import {
  apiStore,
  apiGet,
  apiPost,
  generateFormData,
} from '../../modules/apiHelper';

export default {
  /**
   * combines two almost similar action methods
   * - getActiveClasses() and getEndedClasses().
   * @param {Object} context vuex context
   * @param {String} type must be capitalized [Active | Ended]
   */
  async getTypeClasses(context, type = 'Active') {
    const apiEndpoints = {
      Active: 'list_class',
      Ended: 'list_class_end',
    };

    // do not cache
    const request = apiGet(apiEndpoints[type], 14, undefined, true, false)
      .catch((err) => {
        console.warn(`get${type}Classes::${apiEndpoints[type]} err`, err);
        return false;
      })
      .then(async (res) => {
        if (!res) return false;
        if (res.data.error) return false;

        console.log(res.data);

        return res.data[apiEndpoints[type]];
      });
    //


    // wait for the xhr
    const result = await request;
    return result;
  },

  /**
   * get a single class
   */
  async getClass(context, classId) {
    // ! wish it was an API here
    const classes = await Promise.all([
      context.dispatch('getTypeClasses', 'Active'),
      context.dispatch('getTypeClasses', 'Ended'),
    ]).then(([data1, data2]) => [...data1, ...data2]);

    // find the class by id
    return classes.find((c) => Number(c.id) === Number(classId));
  },

  async getClassList(context, classId) {
    const request = apiGet('training_list', 14, undefined, true, false)
      .catch((err) => {
        console.warn('getClassList::training_list err', err);
        return false;
      })
      .then(async (res) => {
        if (!res) return false;
        if (res.data.error) return false;

        console.log(res.data);

        const trainingList = res.data.training_list.filter((list) => Number(list.classid) === Number(classId));

        const trainingListUserIds = trainingList.map((l) => l.userid);
        const req = [];
        const req2 = [];

        trainingListUserIds.forEach(async (userId) => {
          req.push(apiStore.removeItem(`user/${userId}`));
        });

        await Promise.all(req);

        trainingListUserIds.forEach(async (userId) => {
          req2.push(context.dispatch('getUserData', userId));
        });

        const result = await Promise.all(req2);

        return result;
      });
    //


    // wait for the xhr
    const result = await request;
    return result;
  },

  /**
   * 1. GET verified mentors
   * 2. get classlist in all classes i.e training_list API
   * 3. get students not in training_list
   */
  async getStudentsNotInAnyClass(context) {
    // ! TODO: get mentors from all companies
    const mentorAccountsReq = context.dispatch('getAccounts', ['mentor', 'verified', 1]);
    const trainingListReq = apiGet('training_list', 14, undefined, true, false)
      .catch((err) => {
        console.warn('getStudentsNotInAnyClass::training_list err', err);
        return false;
      })
      .then(async (res) => {
        if (!res) return false;
        if (res.data.error) return false;

        return res.data.training_list;
      });
    //

    const studentsList = await Promise.all([mentorAccountsReq, trainingListReq])
      .then(([mentorAccounts, trainingList]) => {
        // console.log(mentorAccounts);
        // get students not in classlist
        const students = mentorAccounts.map((acc) => {
          // find if student is in another/current class
          const student = trainingList.find((t) => Number(t.userid) === Number(acc.id));
          if (!student) {
            return acc;
          }

          return false;
        });


        return _compact(students);
      });
    //

    return studentsList;
  },

  async saveClass(context, data) {
    const createClass = async () => {
      const formData = generateFormData({
        class_name: data.name,
        description: data.description,
        start: data.start,
        end: data.end,
      });

      const classReq = await resolve(apiPost('create_class', formData, 14));

      return classReq;
    };

    const updateClass = async () => {
      const classNameReq = resolve(apiPost(
        'edit_class_name',
        generateFormData({
          classid: data.id,
          class_name: data.name,
        }),
        14,
      ));

      const classDescReq = resolve(apiPost(
        'edit_class_desc',
        generateFormData({
          classid: data.id,
          description: data.description,
        }),
        14,
      ));

      const classStartReq = resolve(apiPost(
        'edit_class_start',
        generateFormData({
          classid: data.id,
          start: data.start,
        }),
        14,
      ));

      const classEndReq = resolve(apiPost(
        'edit_class_end',
        generateFormData({
          classid: data.id,
          end: data.end,
        }),
        14,
      ));

      const result = await Promise.all([
        classNameReq,
        classDescReq,
        classStartReq,
        classEndReq,
      ]);

      console.log('saveClass::updateClass', result);

      return result.every((r) => !!r);
    };

    let result;

    if (data.id) {
      // run the different apis for updating the class
      result = await updateClass();
    } else {
      // create
      result = await createClass();
    }

    return result;
  },

  async endClass(context, classId) {
    const formData = generateFormData({ classid: classId });

    const request = await resolve(apiPost('end_class', formData, 14));

    return request;
  },

  async addStudent(context, [classId, userId]) {
    if (!classId || !userId) {
      console.error('addStudent err. invalid classId or userId');
      return false;
    }

    const formData = generateFormData({
      userid: userId,
      classid: classId,
    });

    const result = await resolve(apiPost('schedule', formData, 14));

    return result;
  },

  async certifyStudent(context, userId) {
    const formData = generateFormData({ userid: userId });

    const request = await resolve(apiPost('cert', formData, 14));

    return request;
  },
};
